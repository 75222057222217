import React, { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MessengerDrawer from './MessengerDrawer'
import MessengerService from '../../services/messenger.service'
import { Badge, IconButton } from '@mui/material'
import ForumIcon from '@mui/icons-material/Forum'
import newMessageSound from '../../assets/sounds/new_message.mp3'

import './Messenger.css'

let newMessageInterval: NodeJS.Timeout

type MessengerProps = {
  documentTitle: string
  isMessengerActive: boolean
  setOpen: (open: boolean) => void
}

const Messenger: FunctionComponent<MessengerProps> = ({
  documentTitle,
  setOpen,
  isMessengerActive,
}) => {
  const { t } = useTranslation()
  const defaultTitle = documentTitle

  const [messengerOpen, setMessengerOpen] = useState<boolean>(false)
  const [unreadMessage, setUnreadMessages] = useState<number>(0)
  const [title, setTitle] = useState<string>(defaultTitle)

  useEffect(() => {
    document.title = title
  }, [title])

  const toggleMessengerDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setMessengerOpen(open)
      setOpen(open)
    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MessengerService.getUnreadMessagesCount()
        setUnreadMessages((prevState) => {
          if (
            prevState !== response.data.unreadMessages &&
            response.data.unreadMessages > 0
          ) {
            // play sound
            const audio = new Audio(newMessageSound)
            audio.play()

            // show blinking title info
            if (!newMessageInterval) {
              newMessageInterval = setInterval(function () {
                setTitle((prevState) => {
                  if (prevState === defaultTitle) {
                    return t('messenger.newMessage')
                  }
                  return defaultTitle
                })
              }, 1000)
            }
          }
          if (
            prevState !== response.data.unreadMessages &&
            response.data.unreadMessages === 0
          ) {
            setTitle(defaultTitle)
            if (newMessageInterval) {
              clearInterval(newMessageInterval)
            }
          }
          return response.data.unreadMessages
        })
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      }
    }

    const interval = setInterval(() => {
      if (isMessengerActive) {
        fetchData()
      }
    }, 3000)
    return () => clearInterval(interval)
  }, [t, defaultTitle, isMessengerActive])

  return (
    <>
      <IconButton
        color="inherit"
        onClick={toggleMessengerDrawer(!messengerOpen)}
        disabled={!isMessengerActive}
      >
        <Badge
          badgeContent={unreadMessage}
          color="error"
          invisible={!isMessengerActive || unreadMessage === 0}
        >
          <ForumIcon />
        </Badge>
      </IconButton>

      <MessengerDrawer
        messengerOpen={messengerOpen}
        toggleMessengerDrawer={toggleMessengerDrawer}
      />
    </>
  )
}

export default Messenger
