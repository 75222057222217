import { combineReducers } from 'redux'
import auth from './auth'
import message from './message'
import logo from './logo'

const rootReducer = combineReducers({
  auth,
  message,
  logo,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
