const keys: { [key: number]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 }

function preventDefault(e: Event): void {
  e.preventDefault()
}

function preventDefaultForScrollKeys(e: KeyboardEvent): boolean {
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
  return true
}

let supportsPassive: boolean = false
try {
  window.addEventListener(
    'test',
    null as any,
    Object.defineProperty({}, 'passive', {
      get: function () {
        return (supportsPassive = true)
      },
    }),
  )
} catch (e) {}

const wheelOpt: boolean | AddEventListenerOptions = supportsPassive
  ? { passive: false }
  : false
const wheelEvent: string =
  'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

function disableScroll(): void {
  const mainPanelContainer = document.getElementById('main-panel-container')
  if (mainPanelContainer) {
    window.addEventListener('DOMMouseScroll', preventDefault, false) // old Firefox
    window.addEventListener(wheelEvent, preventDefault, wheelOpt) // desktop browsers
    window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false)
    mainPanelContainer.style.overflowY = 'hidden'
  }
}

function enableScroll(): void {
  const mainPanelContainer = document.getElementById('main-panel-container')
  if (mainPanelContainer) {
    window.removeEventListener('DOMMouseScroll', preventDefault, false)
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
    window.removeEventListener('touchmove', preventDefault, wheelOpt)
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
    mainPanelContainer.style.overflowY = ''
  }
}

export { disableScroll, enableScroll }
