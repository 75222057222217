import React, { FunctionComponent, useState } from 'react'

// import './Messenger.css'
import MessengerChannels from './partials/MessengerChannels'
import MessengerUsers from './partials/MessengerUsers'
import MessengerChat from './partials/MessengerChat'
import MessengerGroups from './partials/MessengerGroups'
import MessengerChatGroup from './partials/MessengerChatGroup'
import MessengerAddGroup from './partials/MessengerAddGroup'
import MessengerUsersSelection from './partials/MessengerUsersSelection'
import { useTranslation } from 'react-i18next'
import MessengerEditGroup from './partials/MessengerEditGroup'
import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  IconButton,
  Drawer,
  styled,
} from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import ViewListIcon from '@mui/icons-material/ViewList'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import CloseIcon from '@mui/icons-material/Close'

const MessengerContainer = styled('div')({
  width: 780,
  display: 'flex',
  height: '100%',
})

const StyledTabs = styled(Tabs)`
  .MuiTabs-root {
    background: #e6e6e6;
    min-height: 24px;
  }
  & .MuiTabs-indicator {
    top: 0;
  }
`

const StyledTab = styled(Tab)({
  '.MuiTab-root': {
    flexDirection: 'row',
    textTransform: 'capitalize',
    '& > svg': {
      marginBottom: '0px !important',
      marginRight: '5px',
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#fff',
  },
  '& .MuiTab-fullWidth': {
    minHeight: '24px',
  },
})

const StyledMessengerDrawer = styled(Drawer)({
  '& .MuiDrawer-paperAnchorRight': {
    top: '64px',
    height: 'calc(100% - 64px)',
  },
})

type MessengerDrawerProps = {
  messengerOpen: boolean
  toggleMessengerDrawer: (
    open: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

const MessengerDrawer: FunctionComponent<MessengerDrawerProps> = ({
  messengerOpen,
  toggleMessengerDrawer,
}) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<number>(0)
  const [activeChannelId, setActiveChannelId] = useState<number | null>(null)
  const [activeUserId, setActiveUserId] = useState<number | null>(null)
  const [activeGroupId, setActiveGroupId] = useState<number | null>(null)
  const [addGroup, setAddGroup] = useState<boolean>(false)
  const [editGroup, setEditGroup] = useState<boolean>(false)
  const [editGroupId, setEditGroupId] = useState<number | null>(null)
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [regionView, setRegionView] = useState<boolean>(false)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    setActiveUserId(null)
    setActiveGroupId(null)
  }

  const handleChannelChange = (channelId: number) => {
    setActiveChannelId(channelId)
    setActiveUserId(null)
    setActiveGroupId(null)
  }

  const closeMessengerDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    toggleMessengerDrawer(false)(event)
    setActiveUserId(null)
    setActiveGroupId(null)
    setSelectedUsers([])
  }

  const onSelectedUsers = (users: number[]) => {
    setSelectedUsers(users)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const renderMessenger = () => (
    <MessengerContainer role="presentation">
      <div style={{ width: '349px', borderRight: '1px solid #404040' }}>
        <MessengerChannels
          onChannelChange={handleChannelChange}
          isActive={!editGroup}
        />
        <AppBar position="static" color="default" elevation={0}>
          <StyledTabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <StyledTab
              icon={<PersonOutlineOutlinedIcon />}
              label={t('messenger.tabs.users')}
              disabled={addGroup || editGroup || tabValue === 0}
            />
            <StyledTab
              icon={<GroupOutlinedIcon />}
              label={t('messenger.tabs.groups')}
              disabled={addGroup || editGroup || tabValue === 1}
            />
          </StyledTabs>
        </AppBar>
        <div style={{ padding: '10px', borderBottom: '1px solid #ced4da' }}>
          <TextField
            id="outlined-search"
            label={t('messenger.search')}
            type="search"
            variant="outlined"
            size="small"
            fullWidth
            value={searchValue}
            onChange={handleSearchChange}
          />
          {(tabValue === 0 || editGroup) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              {!regionView && (
                <Tooltip title={t('messenger.regionsView') as string}>
                  <IconButton
                    aria-label="region-view"
                    size="small"
                    onClick={() => setRegionView(true)}
                  >
                    <MapOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {regionView && (
                <Tooltip title={t('messenger.listView') as string}>
                  <IconButton
                    aria-label="list-view"
                    size="small"
                    onClick={() => setRegionView(false)}
                  >
                    <ViewListIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                aria-label="add-group"
                size="small"
                onClick={() => {
                  setAddGroup(true)
                  setSelectedUsers([])
                }}
                disabled={addGroup || editGroup}
              >
                <GroupAddOutlinedIcon fontSize="inherit" />
              </IconButton>
            </div>
          )}
        </div>
        {tabValue === 0 && activeChannelId && !addGroup && !editGroup && (
          <MessengerUsers
            channelId={activeChannelId}
            onUserSelect={setActiveUserId}
            search={searchValue}
            regionView={regionView}
          />
        )}
        {tabValue === 1 && activeChannelId && !addGroup && !editGroup && (
          <MessengerGroups
            channelId={activeChannelId}
            onGroupSelect={setActiveGroupId}
            search={searchValue}
          />
        )}
        {(addGroup || editGroup) && (
          <MessengerUsersSelection
            selectedUsers={selectedUsers}
            onSelectedUsers={onSelectedUsers}
            search={searchValue}
            regionView={regionView}
          />
        )}
      </div>
      <div style={{ width: '430px', position: 'relative' }}>
        <Tooltip title={t('messenger.close') as string}>
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 2 }}
            onClick={closeMessengerDrawer}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {activeChannelId && activeUserId && !addGroup && !editGroup && (
          <MessengerChat
            channelId={activeChannelId}
            peerId={activeUserId}
          />
        )}
        {activeGroupId && !addGroup && !editGroup && (
          <MessengerChatGroup
            groupId={activeGroupId}
            onGroupEdit={(groupId) => {
              setEditGroupId(groupId)
              setEditGroup(true)
              setRegionView(false)
            }}
            onUserSelect={(userId) => {
              setActiveUserId(userId)
              setActiveGroupId(null)
              setTabValue(0)
            }}
          />
        )}
        {activeChannelId && addGroup && (
          <MessengerAddGroup
            selectedUsers={selectedUsers}
            onGroupCreationCancel={() => {
              setAddGroup(false)
              setSelectedUsers([])
              setActiveUserId(null)
            }}
            onGroupCreationSuccess={() => {
              setAddGroup(false)
              setSelectedUsers([])
              setActiveUserId(null)
              setTabValue(1)
            }}
            channelId={activeChannelId}
          />
        )}
        {editGroup && editGroupId && (
          <MessengerEditGroup
            groupId={editGroupId}
            selectedUsers={selectedUsers}
            onGroupUpdateCancel={() => {
              setEditGroup(false)
              setEditGroupId(null)
              setSelectedUsers([])
              setActiveUserId(null)
            }}
            onGroupUpdateSuccess={() => {
              setEditGroup(false)
              setEditGroupId(null)
              setSelectedUsers([])
              setActiveUserId(null)
              setActiveGroupId(null)
              setTabValue(1)
            }}
            onSelectedUsers={onSelectedUsers}
          />
        )}
      </div>
    </MessengerContainer>
  )

  return (
    <StyledMessengerDrawer
      anchor="right"
      open={messengerOpen}
      onClose={closeMessengerDrawer}
    >
      {renderMessenger()}
    </StyledMessengerDrawer>
  )
}

export default MessengerDrawer
