import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../helpers/errorHandler'
import ImageService from '../../services/image.service'
import { LogoImageDetails } from '../../store/Image/types'
import { CircularProgress } from '@mui/material'
import Logo from '../../assets/images/logos/logo.png'

type LogoImageProps = {
  style?: React.CSSProperties
  customClass: string
  refresh?: boolean
}
const LogoImage: React.FunctionComponent<LogoImageProps> = ({
  style,
  customClass,
  refresh,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [image, setImage] = useState<LogoImageDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const imageResponse = await ImageService.getLogoImageContent()
        if (imageResponse.data.imageContent) {
          setImage(imageResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [t, refresh])

  return (
    <>
      {loading && (
        <>
          <CircularProgress />
        </>
      )}
      {!loading && image && (
        <img
          style={style}
          className={customClass}
          src={`data:${image.imageMimeType};base64,${image.imageContent}`}
          alt={image.name}
        />
      )}
      {!loading && !image && (
        <img className={customClass} src={Logo} alt="Logo" />
      )}
    </>
  )
}

export default LogoImage
