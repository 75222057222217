export interface LogoState {
  logoRefresh: boolean
}

const initialState: LogoState = {
  logoRefresh: false,
}

interface Action {
  type: string
}

const logo = (state = initialState, action: Action): LogoState => {
  switch (action.type) {
    case 'SET_LOGO_REFRESH':
      return {
        ...state,
        logoRefresh: !state.logoRefresh,
      }
    default:
      return state
  }
}

export default logo
