import React from 'react'
import { Stack, CircularProgress } from '@mui/material'

const LoadingSpinner: React.FunctionComponent = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      my={2}
    >
      <CircularProgress />
    </Stack>
  )
}

export default LoadingSpinner
