import { styled } from '@mui/material/styles'
import Tour from 'reactour'

export const RootDiv = styled('div')({
  display: 'flex',
})

export const MainContent = styled('main')({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
})

export const TourComponent = styled(Tour)(({ theme }) => ({
  '[data-tour-elem="badge"]': {
    color: theme.colorsPalette.gray.gray6,
    backgroundColor: theme.colorsPalette.orange.dark,
  },
  '.reactour__dot': {
    '&--is-active': {
      backgroundColor: theme.colorsPalette.orange.dark,
    },
  },
}))
