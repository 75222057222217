import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FunctionComponent } from 'react'

const CopyrightContent = styled(Typography)(({ theme }) => ({
  '.footer-txt': {
    textAlign: 'center',
    color: theme.colorsPalette.gray.gray5,
    fontSize: '.75rem',
    width: '100%',
    '.p4t-text-color': {
      color: theme.colorsPalette.orange.medium,
    },
    '&_top': {
      a: {
        color: theme.colorsPalette.gray.gray5,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '&_bottom': {
      a: {
        color: theme.colorsPalette.gray.gray5,
      },
    },
  },
}))

const Copyright: FunctionComponent = () => {
  return (
    <CopyrightContent>
      <div className="footer-txt">
        <div className="footer-txt_top">
          Operatorem systemu jest{' '}
          <span className="p4t-text-color">T-create</span>{' '}
          <a
            href="https://tcreate.pl"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.tcreate.pl
          </a>
        </div>
        <div className="footer-txt_bottom">
          <a
            href="https://rodo.p4t.com.pl/Polityka_prywatnosci_p4t.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zasady korzystania z serwisu i przetwarzania danych.
          </a>
        </div>
      </div>
    </CopyrightContent>
  )
}

export default Copyright
