import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material'
import SecondaryButton from '../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../styles/Buttons/PrimaryButton'

type FunctionalDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  title?: string | null
  content: string | null
  acceptBtnLabel: string
  cancelBtnLabel: string
  executeFunction: () => void
}

const FunctionalDialog: React.FunctionComponent<FunctionalDialogProps> = ({
  open,
  handleClose,
  title,
  content,
  acceptBtnLabel,
  cancelBtnLabel,
  executeFunction,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogContent>
        <DialogContentText color="#000" textAlign="center">
          {title && <h4>{title}</h4>}
          {content && (
            <div
              style={{ fontSize: '14px', lineHeight: '24px' }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
          >
            {cancelBtnLabel}
          </SecondaryButton>
          <PrimaryButton onClick={executeFunction}>
            {acceptBtnLabel}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default FunctionalDialog
