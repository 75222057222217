import React, { Suspense, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import './App.css'
import './locales/i18n'

import Login from './components/Pages/Login/Login'

import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import Header from './components/Pages/shared/Header'
import Navigation from './components/Pages/shared/Navigation'
import { checkAuth } from './helpers/checkAuth'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { ApplicationModule } from './store/Config/types'
import ConfigService from './services/config.service'
import { errorHandler } from './helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import { isModuleVisible } from './helpers/utils'
import LoadingSpinner from './components/shared/LoadingSpinner'
import { MainContent, RootDiv, TourComponent } from './styles'
import { TourData, TourStep } from './store/Tooltips/types'
import TooltipService from './services/tooltip.service'
import { disableScroll, enableScroll } from './helpers/scrollControl'

const PasswordReset = React.lazy(
  () => import('./components/Pages/PasswordReset/PasswordReset'),
)
const Users = React.lazy(() => import('./components/Pages/Users/Users'))
// const PeriodPlans = React.lazy(
//   () => import('./components/Pages/PeriodPlans/PeriodPlans'),
// )
const Periods = React.lazy(() => import('./components/Pages/Periods/Periods'))
const Edubox = React.lazy(() => import('./components/Pages/Edubox/Edubox'))
const Import = React.lazy(() => import('./components/Pages/Import/Import'))
const Game = React.lazy(() => import('./components/Pages/Game/Game'))
const TradeActions = React.lazy(
  () => import('./components/Pages/TradeActions/TradeActions'),
)
const Plans = React.lazy(() => import('./components/Pages/Plans/Plans'))
const Organization = React.lazy(
  () => import('./components/Pages/Organization/Organization'),
)
const ChallengesUsers = React.lazy(
  () => import('./components/Pages/ChallengesUsers/ChallengesUsers'),
)
const Settings = React.lazy(
  () => import('./components/Pages/Settings/Settings'),
)
const Clients = React.lazy(() => import('./components/Pages/Clients/Clients'))
const Challenges = React.lazy(
  () => import('./components/Pages/Challenges/Challenges'),
)
const Dashboard = React.lazy(
  () => import('./components/Pages/Dashboard/Dashboard'),
)
const Images = React.lazy(() => import('./components/Pages/Images/Images'))
const ConfigurationLoyalty = React.lazy(
  () => import('./components/Pages/ConfigurationLoyalty/ConfigurationLoyalty'),
)
const UsersPlans = React.lazy(
  () => import('./components/Pages/UsersPlans/UsersPlans'),
)
const UsersPlansUpdate = React.lazy(
  () => import('./components/Pages/UsersPlansUpdate/UsersPlansUpdate'),
)
const UsersPeriods = React.lazy(
  () => import('./components/Pages/UsersPeriods/UsersPeriods'),
)
const StoresPlans = React.lazy(
  () => import('./components/Pages/StoresPlans/StoresPlans'),
)
const StoresDiscounts = React.lazy(
  () => import('./components/Pages/StoresDiscounts/StoresDiscounts'),
)

const Orders = React.lazy(() => import('./components/Pages/Orders/Orders'))
const Products = React.lazy(
  () => import('./components/Pages/Products/Products'),
)
const Manufacturers = React.lazy(
  () => import('./components/Pages/Manufacturers/Manufacturers'),
)
const ChallengesStores = React.lazy(
  () => import('./components/Pages/ChallengesStores/ChallengesStores'),
)

const LoyaltyUsers = React.lazy(
  () => import('./components/Pages/LoyaltyUsers/LoyaltyUsers'),
)
const LoyaltyProtocols = React.lazy(
  () => import('./components/Pages/LoyaltyProtocols/LoyaltyProtocols'),
)
const LoyaltyPlans = React.lazy(
  () => import('./components/Pages/LoyaltyPlans/LoyaltyPlans'),
)
const LoyaltyPeriods = React.lazy(
  () => import('./components/Pages/LoyaltyPeriods/LoyaltyPeriods'),
)
const Operations = React.lazy(
  () => import('./components/Pages/Operations/Operations'),
)
const Awards = React.lazy(() => import('./components/Pages/Awards/Awards'))
const LoyaltyOperations = React.lazy(
  () => import('./components/Pages/LoyaltyOperations/LoyaltyOperations'),
)
const LoyaltyAwards = React.lazy(
  () => import('./components/Pages/LoyaltyAwards/LoyaltyAwards'),
)
const LoyaltyParticipantPlans = React.lazy(
  () =>
    import(
      './components/Pages/LoyaltyParticipantPlans/LoyaltyParticipantPlans'
    ),
)
const Home = React.lazy(() => import('./components/Pages/Home/Home'))
const TeamPoints = React.lazy(
  () => import('./components/Pages/TeamPoints/TeamPoints'),
)
const NotesAttachmentsDownload = React.lazy(
  () =>
    import(
      './components/Pages/NotesAttachmentsDownload/NotesAttachmentsDownload'
    ),
)
const StoresNotes = React.lazy(
  () => import('./components/Pages/StoresNotes/StoresNotes'),
)
const Story = React.lazy(() => import('./components/Pages/Story/Story'))

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //tour
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [isTourAvailable, setIsTourAvailable] = useState<boolean>(false)
  const [tourData, setTourData] = useState<TourData[]>()

  const [open, setOpen] = useState<boolean>(false)
  const [applicationModules, setApplicationModules] = React.useState<
    ApplicationModule[]
  >([])
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const [loading, setLoading] = React.useState<boolean>(true)

  const lackOfStepsPlaceholder = [
    {
      selector: '#lack-of-tour-steps',
      content: () => (
        <div>
          <strong>Przepraszamy!</strong>
          <br />
          Nie ma podpowiedzi do tej strony.
        </div>
      ),
    },
  ]

  const [tourSteps, setTourSteps] = useState<any>(lackOfStepsPlaceholder)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage())
    })
  }, [dispatch])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tooltipsDataResponse = await TooltipService.getTooltips()
        if (tooltipsDataResponse.data) {
          setTourData(tooltipsDataResponse.data.pagesInfo)
        }
        const applicationModulesResponse =
          await ConfigService.getApplicationModules()
        if (applicationModulesResponse.data) {
          setApplicationModules(applicationModulesResponse.data.modules)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }

    if (isLoggedIn) {
      fetchData()
    }
    if (!isLoggedIn) {
      setLoading(false)
    }
  }, [t, isLoggedIn])

  const [locState, setLocState] = useState<any>(history.location.pathname)
  useEffect(() => {
    history.listen((location, action) => {
      setLocState(location.pathname)
    })
  })

  useEffect(() => {
    const fetchData = async (data: TourStep[]) => {
      const tooltipData = data.map((element) => ({
        selector: element.selector,
        content: () => (
          <div
            className="tour-content"
            dangerouslySetInnerHTML={{ __html: element.content }}
          ></div>
        ),
      }))

      return tooltipData
    }

    const getData = async () => {
      try {
        let foundPage
        const stepsPlaceholder = [
          {
            selector: '#lack-of-tour-steps',
            content: () => (
              <div>
                <strong>Przepraszamy!</strong>
                <br />
                Nie ma podpowiedzi do tej strony.
              </div>
            ),
          },
        ]
        if (tourData && tourData?.length > 0) {
          foundPage = tourData.find((page) => page.path === locState)
        }

        if (foundPage && locState === foundPage.path) {
          setIsTourAvailable(false)
          const stepsFromPage = await fetchData(foundPage.data)
          if (stepsFromPage.length > 0) {
            setTourSteps(stepsFromPage)
            setIsTourAvailable(true)
          } else {
            setIsTourAvailable(false)
            setTourSteps(stepsPlaceholder)
          }
        } else {
          setIsTourAvailable(false)
          setTourSteps(stepsPlaceholder)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }

    getData()
  }, [locState, t, tourData])

  return (
    <RootDiv>
      <CssBaseline />
      {currentUser && isLoggedIn && !loading && (
        <>
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            onSetTourOpen={setIsTourOpen}
            isTourAvailable={isTourAvailable}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
          <Navigation
            open={open}
            handleDrawerClose={handleDrawerClose}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
        </>
      )}
      <MainContent id="main-panel-container">
        {loading && <LoadingSpinner />}

        {!loading && (
          <>
            <TourComponent
              steps={tourSteps ? tourSteps : lackOfStepsPlaceholder}
              isOpen={isTourOpen}
              rounded={0}
              onRequestClose={() => {
                setIsTourOpen(false)
              }}
              update={locState}
              startAt={0}
              onAfterOpen={disableScroll}
              onBeforeClose={enableScroll}
            />
            <Router history={history}>
              <div style={{ height: '100px' }} />
              <Suspense fallback={<LoadingSpinner />}>
                <Switch>
                  {isModuleVisible(
                    'DASHBOARD',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/dashboard']}
                      render={(props) => (
                        <Dashboard applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'USER_CHALLENGE',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={[
                        '/challenges-users/:challengeId/period/:periodId/status/:statusCode',
                        '/challenges-users',
                      ]}
                      component={ChallengesUsers}
                    />
                  )}

                  {isModuleVisible(
                    'STORE_CHALLENGE',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/challenges-stores']}
                      component={ChallengesStores}
                    />
                  )}

                  {isModuleVisible(
                    'CHALLENGE',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/challenges']} component={Challenges} />}

                  {/* <Route path={['/period-plans']} component={PeriodPlans} /> */}

                  {isModuleVisible(
                    'STORE',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/clients']}
                      render={(props) => (
                        <Clients applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'LEADERBOARD',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/game']} component={Game} />}

                  {(isModuleVisible('USER', applicationModules, currentUser) ||
                    isModuleVisible('ADMIN', applicationModules, currentUser) ||
                    isModuleVisible('ASM', applicationModules, currentUser) ||
                    isModuleVisible(
                      'KAM',
                      applicationModules,
                      currentUser,
                    )) && (
                    <Route
                      path={['/users']}
                      render={(props) => (
                        <Users applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'NOTES',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/stores-notes']}
                      render={(props) => (
                        <StoresNotes applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'TEAM_POINTS',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/team-points']} component={TeamPoints} />}

                  {isModuleVisible(
                    'PERIOD',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/periods']} component={Periods} />}

                  {isModuleVisible(
                    'EDU_BOX',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/edubox']} component={Edubox} />}

                  <Route path={['/import/:name/:param1?']} component={Import} />

                  {isModuleVisible(
                    'INFO_BOX',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/actions']} component={TradeActions} />}

                  {isModuleVisible(
                    'IMAGE',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/images']} component={Images} />}

                  {isModuleVisible(
                    'USER_PLAN',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      exact
                      path={['/plans/users']}
                      component={UsersPlans}
                    />
                  )}

                  {isModuleVisible(
                    'STORE_PLAN',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      exact
                      path={['/plans/stores']}
                      component={StoresPlans}
                    />
                  )}

                  {isModuleVisible(
                    'STORE_DISCOUNTS',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/stores-discounts']}
                      component={StoresDiscounts}
                    />
                  )}

                  {isModuleVisible(
                    'MODIFY_PLAN',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/user-plans']}
                      component={UsersPlansUpdate}
                    />
                  )}
                  {isModuleVisible(
                    'USER_PERIOD',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route path={['/users-periods']} component={UsersPeriods} />
                  )}

                  {isModuleVisible('PLAN', applicationModules, currentUser) && (
                    <Route path={['/plans-manage']} component={Plans} />
                  )}

                  {(isModuleVisible(
                    'GENERAL_SETTINGS',
                    applicationModules,
                    currentUser,
                  ) ||
                    isModuleVisible(
                      'SYSTEM_SETTINGS',
                      applicationModules,
                      currentUser,
                    )) && (
                    <Route
                      path={['/settings']}
                      render={(props) => (
                        <Settings applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'CONFIG_LOYALTY',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/configuration-loyalty']}
                      render={(props) => (
                        <ConfigurationLoyalty
                          applicationModules={applicationModules}
                        />
                      )}
                    />
                  )}

                  {(isModuleVisible(
                    'COMPANY',
                    applicationModules,
                    currentUser,
                  ) ||
                    isModuleVisible(
                      'REGION',
                      applicationModules,
                      currentUser,
                    )) && (
                    <Route
                      path={['/organization']}
                      render={(props) => (
                        <Organization applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  {isModuleVisible(
                    'STORE_ORDERS',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/orders']} component={Orders} />}

                  {isModuleVisible(
                    'PRODUCT',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/products']} component={Products} />}

                  {isModuleVisible(
                    'MANUFACTURER',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/manufacturers']}
                      component={Manufacturers}
                    />
                  )}

                  {isModuleVisible(
                    'LOYALTY_USER',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route path={['/loyalty-users']} component={LoyaltyUsers} />
                  )}

                  {isModuleVisible(
                    'LOYALTY_PROTOCOL',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/loyalty-protocols']}
                      component={LoyaltyProtocols}
                    />
                  )}

                  {isModuleVisible(
                    'LOYALTY_PLAN',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route path={['/loyalty-plans']} component={LoyaltyPlans} />
                  )}

                  {isModuleVisible(
                    'PARTICIPANT_PLAN',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/loyalty-participant-plans']}
                      component={LoyaltyParticipantPlans}
                    />
                  )}

                  {isModuleVisible(
                    'GAME_OPERATION_LIST',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/operations']} component={Operations} />}

                  {isModuleVisible(
                    'GAME_AWARDS',
                    applicationModules,
                    currentUser,
                  ) && <Route path={['/awards']} component={Awards} />}

                  {isModuleVisible(
                    'LOYALTY_OPERATION_LIST',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/loyalty-operations']}
                      component={LoyaltyOperations}
                    />
                  )}

                  {isModuleVisible(
                    'LOYALTY_AWARDS',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/loyalty-awards']}
                      component={LoyaltyAwards}
                    />
                  )}

                  {isModuleVisible(
                    'LOYALTY_PERIOD',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/loyalty-periods']}
                      component={LoyaltyPeriods}
                    />
                  )}

                  {isModuleVisible(
                    'STORY_LEVELS',
                    applicationModules,
                    currentUser,
                  ) && (
                    <Route
                      path={['/story']}
                      render={(props) => (
                        <Story applicationModules={applicationModules} />
                      )}
                    />
                  )}

                  <Route exact path={['/', '/home']} component={Home} />

                  <Route exact path="/login" component={Login} />
                  <Route
                    path="/password/reset/:token"
                    component={PasswordReset}
                  />

                  <Route
                    path={'/note/attachment/download/:uuid'}
                    component={NotesAttachmentsDownload}
                  />
                  {/* If root is incorrect, page redirects user to the Home page */}
                  <Route path="*">
                    <Redirect to="/home" />
                  </Route>
                  <Redirect to="/login" />
                </Switch>
              </Suspense>
            </Router>
          </>
        )}

        {currentUser && (
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ whiteSpace: 'pre-line' }}
          />
        )}
      </MainContent>
    </RootDiv>
  )
}

export default App
