import React, { FunctionComponent, useEffect, useState } from 'react'
import MessengerService from '../../../services/messenger.service'

import { Group } from '../../../store/Messenger/types'
import ForumIcon from '@mui/icons-material/Forum'
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
} from '@mui/material'
import LoadingSpinner from '../../shared/LoadingSpinner'

type MessengerGroupsProps = {
  channelId: number
  onGroupSelect: (groupId: number) => void
  search: string
}

const MessengerGroups: FunctionComponent<MessengerGroupsProps> = ({
  channelId,
  onGroupSelect,
  search,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [groups, setGroups] = useState<Group[]>([])
  const [groupsFiltered, setGroupsFiltered] = useState<Group[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupListResponse = await MessengerService.getGroupList(channelId)
        setGroups(groupListResponse.data.groupConversationList)
        setGroupsFiltered(groupListResponse.data.groupConversationList)
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    setSelectedGroup(null)
    fetchGroups()
    const interval = setInterval(() => {
      fetchGroups()
    }, 3000)
    return () => clearInterval(interval)
  }, [channelId])

  useEffect(() => {
    if (search !== '') {
      const filteredGroups = groups.filter((group) =>
        group.name?.toLowerCase().includes(search.toLowerCase()),
      )
      setGroupsFiltered(filteredGroups)
    } else {
      setGroupsFiltered(groups)
    }
  }, [search, groups])

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    const foundGroup = groups.find((group) => group.id === index)
    setSelectedGroup(foundGroup || null)
    if (foundGroup) {
      onGroupSelect(foundGroup.id)
    }
  }

  const renderGroupItem = (
    groupId: number,
    name: string | null,
    newMessages: boolean,
    lastMessage: string | null,
    iconMimeType: string | null,
    iconContent: string | null,
  ) => {
    return (
      <ListItem
        button
        selected={selectedGroup?.id === groupId}
        onClick={(event) => handleListItemClick(event, groupId)}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
        key={groupId}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              border: '1px solid #e0e4e7',
            }}
            src={`data:${iconMimeType};base64,${iconContent}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span style={{ fontWeight: newMessages ? 'bold' : 'normal' }}>
              {name}
            </span>
          }
          secondary={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: newMessages ? '#3aaa35' : 'inherit',
                height: '20px',
              }}
            >
              {lastMessage && (
                <>
                  <ForumIcon style={{ fontSize: 20, paddingRight: '5px' }} />
                  {lastMessage.replace('T', ' ')}
                </>
              )}
            </div>
          }
        />
      </ListItem>
    )
  }

  return (
    <List
      style={{
        height: 'calc(100% - 237px)',
        overflowY: 'auto',
      }}
    >
      {loading && <LoadingSpinner />}
      {!loading &&
        groupsFiltered.map((group) => {
          let newMessages = false
          let lastMessage: string | null = null

          newMessages = group.unreadMessages > 0
          lastMessage = group.lastMessageDate

          return renderGroupItem(
            group.id,
            group.name,
            newMessages,
            lastMessage,
            group.iconMimeType,
            group.iconContent,
          )
        })}
    </List>
  )
}

export default MessengerGroups
