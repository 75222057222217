import axios from 'axios'
import authHeader from './authHeader'
import { TourDataResponse } from '../store/Tooltips/types'

const API_URL = process.env.REACT_APP_API_URL

const getTooltips = (): Promise<TourDataResponse> => {
  return axios.get(API_URL + '/api/admin/tooltip', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getTooltips,
}

export default exportedObject
