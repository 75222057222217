import React from 'react'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { Maintenance } from '../../../../store/Auth/types'
import moment from 'moment'

type MaintenanceDialogProps = {
  maintenanceDetails: Maintenance
  open: boolean
  handleClose: (refresh: boolean) => void
}

const MaintenanceDialog: React.FunctionComponent<MaintenanceDialogProps> = ({
  maintenanceDetails,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle style={{ height: '90px' }}>
        <Stack flexDirection="row">
          <Stack justifyContent="center">
            <Typography variant="body1" fontWeight="bold">
              {t('pages.login.maintenanceDialog.title')}
            </Typography>
          </Stack>
          <IconButton
            style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
            onClick={() => handleClose(false)}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} textAlign="left">
              <Typography
                variant="body2"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('pages.login.maintenanceDialog.info', {
                    validFrom: moment(maintenanceDetails.validFrom).format(
                      'DD.MM.YYYY HH:mm',
                    ),
                    validTo: moment(maintenanceDetails.validTo).format(
                      'DD.MM.YYYY HH:mm',
                    ),
                  }),
                }}
              ></Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="flex-end" width="100%">
          <SecondaryButton onClick={() => handleClose(false)}>
            {t('common.close')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default MaintenanceDialog
