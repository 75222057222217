import axios from 'axios'
import { TFunction } from 'react-i18next'
import { toast } from 'react-toastify'

export const errorHandler = (
  error: unknown,
  t: TFunction<'translation'>,
): void => {
  let errorMessage = t('messages.error.generalError')

  if (error instanceof TypeError) {
    console.log('// A TypeError', error)
  } else if (error instanceof RangeError) {
    console.log('// Handle the RangeError')
  } else if (error instanceof EvalError) {
    console.log('// you guessed it: EvalError')
  } else if (typeof error === 'string') {
    console.log('// The error is a string')
  } else if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      switch (error.response.status) {
        case 401:
          errorMessage = error.response.data.message
          break
        case 404:
          errorMessage = error.response.data.detail
          break
        case 422:
          errorMessage = error.response.data.detail
          break
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)
  } else {
    console.log('// everything else')
    console.log(error)
  }

  toast.error(errorMessage)
}

export const deleteImageErrorHandler = (
  error: unknown,
  t: TFunction<'translation'>,
): void => {
  let errorMessage = t('messages.error.generalError')

  if (error instanceof TypeError) {
    console.log('// A TypeError', error)
  } else if (error instanceof RangeError) {
    console.log('// Handle the RangeError')
  } else if (error instanceof EvalError) {
    console.log('// you guessed it: EvalError')
  } else if (typeof error === 'string') {
    console.log('// The error is a string')
  } else if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      switch (error.response.status) {
        case 401:
          errorMessage = error.response.data.message
          break
        case 404:
          errorMessage = error.response.data.detail
          break
        case 422:
          errorMessage = t('imageLibrary.deleteImage.validation.inUse')
          break
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)
  } else {
    console.log('// everything else')
    console.log(error)
  }

  toast.error(errorMessage)
}

export const resetPasswordErrorHandler = (
  error: unknown,
  t: TFunction<'translation'>,
) => {
  let errorMessage = t('messages.error.generalError')

  if (axios.isAxiosError(error)) {
    if (error.response) {
      switch (error.response.status) {
        case 422:
          errorMessage = t('pages.passwordReset.validation.passwordError')
          break
      }
    }
  }

  return errorMessage
}
